/**
 * returns the href link you need for an auto-tweet button
 * encodes your string to make it URL safe
 * based on this guide: https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/overview
 * @param  {String} tweetText the text you want in the tweet
 * @return {String}           auto tweet URL
 */
export default function getTwitterShareLink(text, url) {
  return "https://twitter.com/intent/tweet?text=" + encodeURIComponent(text) + "&url=" + encodeURIComponent(url)
}
