import React from 'react'
import PropTypes from 'prop-types'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare, faLink } from '@fortawesome/free-solid-svg-icons'

import FacebookButton from "./facebook/FacebookButton"
import getFacebookShareLink from "./facebook/getFacebookShareLink"
import TwitterButton from "./twitter/TwitterButton"
import getTwitterShareLink from "./twitter/getTwitterShareLink"
import RedditButton from "./reddit/RedditButton"
import getRedditShareLink from "./reddit/getRedditShareLink"
import EmailButton from "./email/EmailButton"
import getEmailShareLink from "./email/getEmailShareLink"
import isBrowser from "utils/isBrowser"
import writeToClipboard from "utils/clipboard/writeToClipboard"
import "./socialMedia.scss"

class SocialMedia extends React.Component {
  state = {
    copyUrlText: "Copy URL to Clipboard"
  }

  copyUrlToClipboard = () => {
    writeToClipboard(
      isBrowser ? window.location.href : "",
      () => this.setState({copyUrlText: "Copied URL!"}),
      () => this.setState({copyUrlText: "Error copying URL :("})
    )
  }

  render() {
    const href = isBrowser ? window.location.href : ""

    const facebookUrl = getFacebookShareLink(href)
    const twitterUrl = getTwitterShareLink("Share about LobbyView!",href)
    const redditUrl = getRedditShareLink("LobbyView",href)
    const emailUrl = getEmailShareLink(undefined,{
      body: encodeURIComponent(href),
      subject: "Share about LobbyView!",
    })

    return (
      <span className="socialMedia">
        <FacebookButton href={facebookUrl}/> &nbsp;
        <TwitterButton href={twitterUrl}/> &nbsp;
        <RedditButton href={redditUrl}/> &nbsp;
        <EmailButton href={emailUrl}/> &nbsp;
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          overlay={
            <Popover>
              <Popover.Body>
                <div className="socialMediaPopoverHeading">SHARE</div>
                <Row>
                  <Col xs={6}>
                    <SocialMediaButtonAndText button={<FacebookButton href={facebookUrl}/>} text="Share on Facebook" href={facebookUrl}/>
                    <SocialMediaButtonAndText button={<TwitterButton href={twitterUrl}/>} text="Share on Twitter" href={twitterUrl}/>
                  </Col>

                  <Col xs={6}>
                    <SocialMediaButtonAndText button={<RedditButton href={redditUrl}/>} text="Share on Reddit" href={redditUrl}/>
                    <SocialMediaButtonAndText button={<EmailButton href={emailUrl}/>} text="Share via Email" href={emailUrl}/>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="socialMediaButtonAndText oneLine" onClick={this.copyUrlToClipboard}>
                      <span className="popoverButton">
                        <Button className="icon socialMediaShareButton" size="sm">
                          <FontAwesomeIcon icon={faLink}/>
                        </Button>
                      </span>

                      <span className="popoverText">
                        {this.state.copyUrlText}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Popover.Body>
            </Popover>
          }
        >
        <Button className="icon socialMediaShareButton" size="sm">
          <FontAwesomeIcon icon={faShare}/>
        </Button>
        </OverlayTrigger>
      </span>
    )
  }
}

// SocialMedia.propTypes = {
// }

export default SocialMedia


const SocialMediaButtonAndText = props => (
  <a className="socialMediaButtonAndText oneLine" href={props.href} target="_blank" rel="noopener noreferrer">
    <span className="popoverButton">{props.button}</span>
    <span className="popoverText">{props.text}</span>
  </a>
)

SocialMediaButtonAndText.propTypes = {
  button: PropTypes.element.isRequired,
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}
